import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import NavV2 from "../components/NavV2"
import DefaultPage from "../components/DefaultPage"
import PasswordConfirm from "../components/PasswordConfirm"
import FooterContainer from "../components/FooterContainer"

const Equity = () => (

  <>
    <NavV2 />

    <Body>
      <DefaultPage
        title="You're Getting Equity!"
        subtitle=""
        body="We'll update you soon on the properties!"
        />  
     
    </Body>



  </>
)

export default Equity

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  
`
const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 700px;
`