import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Accordion from "../components/Accordion"

const FAQ = () => {
  return (
    <Body>
      <BackTitle>FREQUENTLY ASKED QUESTIONS</BackTitle>
      <Title>FREQUENTLY ASKED QUESTIONS</Title>
      <AccordionBody>
        <Accordion
          title="How Does Cloud Castles Work?"
          content="
        <p>Choose a Cloud Castle at any of our locations that say AVAILABLE NOW in the app</p>
        </br>
        <p>Decide on the amount of the Cloud Castle you'd like to own.  Each Cloud Castle typically has 8,000 available shares available for purchase</p>
        </br>
        <p>Cloud Castles handle all the paper work and insurance so that your portion of the property is safe and operating effectively.  Once it closes, Cloud Castle oversees the property management and heavy lifting.</p>
        </br>
      	<p>Cloud Castles will list the property on vacation rental sites like Airbnb. If the Cloud Castle generates a profit in any given month, users will get paid out in their account in the app the following month.</p>
      	</br>
		<p>Sharing with your friends and family will give you access to free or discounted shares.</p>
        "
        />
        <Accordion
          title="How quickly can I expect a return on investment?"
          content="
        <p>We can’t guarantee when you will receive a return, but the goal is to generate revenue as soon as we list the Cloud Castle on sites like Airbnb.  In whatever month the property profits, we will wait until the end of the month to calculate each individual investor’s payout.</p>
        </br>
        <p>Our goal is between 3-5 years. We look for properties that can secure at least a 20% cash on cash return (annual profit / initial investment). For reference, the industry standard is 15%.</p>
        "
        />
        <Accordion
          title="Will there be a legally binding contract for my investment?"
          content="
        <p>Yes, we will execute an LLC operating agreement for each property that specifies the terms of your ownership interest and payout.</p>

        "
        />
      </AccordionBody>
    </Body>
  )
}
export default FAQ

const Body = styled.div`
  position: relative;
  width: 80%;
  height: 495.9px;
  left: 104.96px;
  top: 0.21px;
  flex: 1;
  display: flex;
  justify-content: center;
  z-index:10px;

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    width: 80%;
    height: 495.9px;
    left: 04.96px;
  }
`

const AccordionBody = styled.div`
  padding: 150px 0px 0px 0px;
`

const BackTitle = styled.h2`
  position: absolute;
  width: 100%;
  height: 99px;
  left: 50.32px;
  top: -10.21px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 65.7269px;
  line-height: 99px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin: auto;

  opacity: 0.04;

  @media only screen and (max-width: 800px) and (min-width: 426px) {
    font-size: 30.7269px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 20.7269px;
  }
`
const Title = styled.h2`
  position: absolute;
  width: 1064px;
  height: 55px;
  left: 40.32px;
  top: 45.51px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 800px) and (min-width: 426px) {
    font-size: 22.7269px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 16.9714px;
  }
`
