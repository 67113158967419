import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

const PasswordConfirm = () => {
  const [pw, setPw] = React.useState("")
  const [pw1, setPw1] = React.useState("")
  const [pwError, setPwError] = React.useState("")
  const [pwSent, setPwSent] = React.useState("")
  const [num, setNum] = useQueryParam("x", StringParam)

  const handleSumit = event => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault()

    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    console.log("num")
    console.log(num)
    console.log(pw)
    console.log(pw1)

    if (pw == pw1 && pw.length > 8) {
      var axios = require("axios")
      var data = JSON.stringify({
        token: num,
        password: pw,
      })

      var config = {
        method: "post",
        url: "https://alpha.cloudcastles.io/reset-password/confirm/",
        // url: "http://127.0.0.1:8000/reset-password/confirm/",
        headers: {
          "Content-Type": "application/json",
          // Cookie:
          //   "csrftoken=bxQmPXIhnRsidq1I27X1JMXLOJ7lCFmbImLKjFVuSsMRazgupeZ1W6GGyOQ3sNRL",
          Accept: "application/json",
        },
        data: data,
      }

      console.log(config)

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setPwSent("Password Reset")
          setPwError("")
        })
        .catch(function (error) {
          console.log(error)
          // var stringg = JSON.stringify({error})
          setPwError("error")
        })
    } else if (pw != pw1) {
      // invalid email, maybe show an error to the user.

      setPwError("")
      setPwError("Password Do Not Match")
    } else if (pw.length < 8) {
      // invalid email, maybe show an error to the user.

      setPwError("")
      setPwError("Password Must Be 8 Characters")
    }

  }

  return (
    <Body>
      <H1Text>Reset Password</H1Text>
      <PText>Add your credentials to reset your password</PText>
      <Form
        style={{
          display: "grid",
          jusityitems: "center",
          alignitems: "center",
        }}
        onSubmit={handleSumit}
      >
        <Input
          type="password"
          placeholder="Password"
          onChange={event => setPw(event.target.value)}
        />
        <Input
          type="password"
          placeholder="Repeat Password"
          onChange={event => setPw1(event.target.value)}
        />
        <Button type="submit">Reset Password</Button>

        {/* <Button as="a" href="/"> */}
        {/*   Signup */}
        {/* </Button> */}
      </Form>
      <Span style={{ color: "red" }}>{pwError}</Span>
      <Span style={{ color: "black" }}>{pwSent}</Span>
    </Body>
  )
}
export default PasswordConfirm


const Body = styled.div`
  display: flex;
  /*align-items: center;*/
  justify-items: center;
  flex-direction: column;
  z-index: 10;
  /*height: 250px;*/
  background: transparent;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  position: relative;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Form = styled.form`
  display: flex;
  /*align-items: center;*/
  justify-items: center;
  flex-direction: column;
`

const Input = styled.input`
  width: 80%;
  padding: 0.5em;
  margin: 0.5em;
  background: white;
  border: none;
  border-radius: 3px;
  background: #ffffff;
  border: 2px solid #4976ff;
  box-sizing: border-box;
  padding: auto 10px;
  box-shadow: 0px 8px 36px rgba(73, 118, 255, 0.2);
  border-radius: 11px;
`

const Button = styled.button`
  width: 80%;
  display: inline-block;
  background: linear-gradient(0deg, #161930, #161930), #ffbc3a;
  box-shadow: 0px 12.6861px 45px rgba(22, 25, 48, 0.19);
  font-size: 0.8em;
  margin: 1em;
  padding: 0.3em 3em;

  border-radius: 11px;
  display: block;

  font-family: Poppins;
  font-style: normal;

  color: #ffffff;
`
const H1Text = styled.h1`
  position: relative;
  /*width: 40%;*/
  height: 89.66px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;
  text-align: center;

  color: white;
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 25.8802px;
  }
`

const PText = styled.p`
  position: relative;
  width: 439.52px;
  height: 43.18px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 16.7436px;
  line-height: 151%;
  color: white;
  /* or 25px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 12.8802px;
  }
`

const Span = styled.span`
  width: 80%;
  margin-left: 50px;
`
